






















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SelectTrainingSessionStatus',
  props: {
    value: {
      type: [Array, String],
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: 'Statut',
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    rules: {
      type: String,
      required: false,
      default: 'required',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      requird: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      status: null,
    }
  },
  created () {
    (this.status as unknown) = this.value
  },
  watch: {
    value: {
      handler () {
        (this.status as unknown) = this.value
      },
    },
  },
  computed: {
    statusItems (): {value: string, icon: string}[] {
      if (this.items.length === 0) {
        return (this as any).$appConfig.trainingSessionStatuses
      } else {
        return this.items as {value: string, icon: string}[]
      }
    },
    getIcon (): string {
      if (!this.value) {
        return ''
      }

      let icon = ''

      ;(this as any).$appConfig.trainingSessionStatuses.forEach((x: any) => {
        if (x.value === this.value) {
          icon = x.icon
          return false
        }
      })
      return icon
    },
  },
  methods: {
    onInput (value: string) {
      // console.log(value, 'input')
      this.$emit('input', value)
    },
    onChange (value: string) {
      // console.log(value, 'change')
      this.$emit('change', value)
    },
  },
})
