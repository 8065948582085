var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:_vm.classes,attrs:{"slot-scope":_vm.hideDetails ? '' :{ errors: errors },"error-messages":_vm.hideDetails ? '' : errors,"items":_vm.statusItems,"item-value":function (item) { return item.value; },"item-text":function (item) { return _vm.$t('training_session.form.status.' + item.value); },"label":_vm.label,"outlined":_vm.outlined,"clearable":_vm.clearable,"prepend-inner-icon":_vm.getIcon,"disabled":_vm.disabled,"multiple":_vm.multiple,"menu-props":{ offsetY: true },"hide-details":_vm.hideDetails,"append-icon":"mdi-chevron-down"},on:{"change":_vm.onChange,"input":_vm.onInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"pr-2",domProps:{"innerHTML":_vm._s(item.icon)}}),_c('span',[_vm._v(_vm._s(_vm.$t('training_session.form.status.' + item.value)))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }